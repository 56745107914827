<template>
  <div class="min-h-screen bg-gray-100 flex flex-col">
    <!-- Header -->
    <header class="bg-blue-800 text-white p-4 flex items-center justify-between md:justify-center shadow-md">
      <img src="@/assets/logo.jpeg" alt="Logo" class="w-12 h-12 bg-blue-900 rounded-full md:w-16 md:h-16" />
      <h1 class="text-2xl font-semibold md:text-3xl">Sang Coolheur</h1>
    </header>

    <!-- Main Content -->
    <main class="flex-1 p-4 md:p-6">
      <div class="max-w-full md:max-w-4xl mx-auto bg-white p-4 md:p-6 rounded-lg shadow-lg">
        <div class="mb-4 md:mb-6 flex flex-col md:flex-row justify-between items-center">
          <input
              type="text"
              v-model="searchQuery"
              placeholder="Rechercher..."
              class="w-full p-2 md:p-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <select
              v-model="itemsPerPage"
              class="mt-4 md:mt-0 md:ml-4 p-2 border border-gray-300 rounded-md shadow-sm"
          >
            <option v-for="option in pageSizeOptions" :key="option" :value="option">
              {{ option }} éléments par page
            </option>
          </select>
        </div>
        <table class="w-full bg-white border border-gray-300 rounded-lg shadow-md">
          <thead>
          <tr class="bg-gray-200 border-b border-gray-300">
            <th class="p-2 md:p-3 text-left text-gray-700 font-semibold">#</th>
            <th v-for="(header, index) in limitedHeaders" :key="index" class="p-2 md:p-3 text-left text-gray-700 font-semibold">
              {{ header }}
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(row, index) in paginatedData" :key="index" :class="{'bg-gray-100': index % 2 === 0, 'hover:bg-gray-200': true, 'border-b border-gray-300': true}">
            <td class="p-2 md:p-3 text-gray-600">{{ (currentPage - 1) * itemsPerPage + index + 1 }}</td>
            <td v-for="(header, idx) in limitedHeaders" :key="idx" class="p-2 md:p-3 text-gray-600">
              {{ row[header] }}
            </td>
          </tr>
          </tbody>
        </table>

        <!-- Pagination Controls -->
        <div class="mt-4 flex flex-col md:flex-row justify-between items-center">
          <button
              @click="prevPage"
              :disabled="currentPage === 1"
              class="bg-blue-600 text-white px-4 py-2 rounded-md shadow-sm disabled:opacity-50"
          >
            Précédent
          </button>
          <span class="text-gray-700">Page {{ currentPage }} sur {{ totalPages }}</span>
          <button
              @click="nextPage"
              :disabled="currentPage === totalPages"
              class="bg-blue-600 text-white px-4 py-2 rounded-md shadow-sm disabled:opacity-50"
          >
            Suivant
          </button>
        </div>
      </div>
    </main>

    <!-- Footer -->
    <footer class="bg-blue-800 text-white p-4 text-center">
      &copy; 2024 Sang Coolheur
    </footer>
  </div>
</template>

<script>
import Papa from 'papaparse';
import csvPath from '@/assets/data.csv';

export default {
  data() {
    return {
      csvData: [],
      headers: [],
      searchQuery: '',
      currentPage: 1,
      itemsPerPage: 10, // Default number of rows per page
      pageSizeOptions: [10, 25, 50, 100] // Options for the number of rows per page
    };
  },
  computed: {
    limitedHeaders() {
      return this.headers.slice(1, 4);
    },
    filteredData() {
      const query = removeAccents(this.searchQuery.toLowerCase());
      if (!this.searchQuery) {
        return this.csvData.map(row => {
          // Return only the first 4 columns of each row
          const limitedRow = {};
          this.limitedHeaders.forEach(header => {
            limitedRow[header] = row[header];
          });
          return limitedRow;
        });
      }
      return this.csvData
          .map(row => {
            // Return only the first 4 columns of each row
            const limitedRow = {};
            this.limitedHeaders.forEach(header => {
              limitedRow[header] = row[header];
            });
            return limitedRow;
          })
          .filter(row =>
              Object.values(row).some(value =>
                  removeAccents(value.toString().toLowerCase()).includes(query)
              )
          );
    },
    paginatedData() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.filteredData.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.filteredData.length / this.itemsPerPage);
    }
  },
  methods: {
    loadCSV() {
      fetch(csvPath)
          .then(response => response.text())
          .then(text => {
            Papa.parse(text, {
              complete: this.handleCSV,
              header: true,
            });
          })
          .catch(error => console.error('Error loading CSV:', error));
    },
    handleCSV(results) {
      if (results && results.data) {
        this.headers = results.meta.fields;
        this.csvData = results.data;
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    }
  },
  mounted() {
    this.loadCSV();
  }
};

// Function to remove accents from a string
function removeAccents(str) {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}
</script>

<style>
/* Additional styling if needed */
</style>
